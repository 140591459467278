<template>
  <div>
    <v-container fluid>
      <v-row dense>
        <v-col key="Histórico" :cols="6">
          <v-card v-if="circuitos" style="height: 645px; width: 100%">
            <v-card-title>
              Rotas
              <v-btn
                title="Inserir Nova Rota"
                class="mx-2"
                fab

                small
                color=primary
                @click="
                  selecionadoLimpar();
                  dialog = true;">
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-data-table
              :headers="hstCircuitosHeader"
              :items="circuitos"
              :loading="loaders.rotas"
              loading-text="A carregar..."
              :search="search"
              no-data-text="Sem resultados"
              no-results-text="Sem resultados"
              hide-default-footer>
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-data-footer
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text="$vuetify.dataTable.itemsPerPageText"/>
              </template>
              <template v-slot:[`item.dataCriacao`]="{ item }">
                {{
                  item.dataCriacao
                    ? new Date(item.dataCriacao).toLocaleString()
                    : ""
                }}
              </template>
              <template v-slot:[`item.dataTermino`]="{ item }">
                {{
                  item.dataTermino
                    ? new Date(item.dataTermino).toLocaleString()
                    : ""
                }}
              </template>
              <template v-slot:[`item.tipo`]="{ item }">
                {{ item.tipo.tipo }}
              </template>

              <template v-slot:[`item.tipo_circuito`]="{ item }">
                {{ item.tipo_circuito.circuito }}
              </template>

              <template v-slot:[`item.Contentors`]="{ item }">
                {{ item.Contentors.length }}
              </template>
              <template v-slot:[`item.id`]="{ item }">
                <v-btn
                  class="mx-2"
                  fab

                  small
                  color=primary
                  @click="pesquisarCircuito(item)">
                  <v-icon>
                    mdi-magnify-plus
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.editar`]="{ item }">
                <v-btn
                  class="mx-2"
                  fab

                  small
                  color=primary
                  @click="editarRota(item)">
                  <v-icon>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
          <br><br>
        </v-col>
        <v-col :cols="6">
          <v-card style="height: 580px; width: 100%">
            <v-card-title>
              Mapa
              <v-spacer></v-spacer>
            </v-card-title>
            <l-map
              v-if="showMap"
              :zoom="zoom"
              :center="center"
              :options="mapOptions"
              @update:center="centerUpdate"
              v-on:update:zoom="updateZoom($event)"
              ref="mymap">
              {{ selecionado.Contentors }}
              <template v-if="selecionado">
                <l-marker
                  id="marker.id"
                  v-for="marker in selecionado.Contentors"
                  :key="marker.id"
                  :visible="marker.position"
                  :draggable="marker.draggable"
                  :lat-lng="
                    getlatLng(
                      marker.contentor ? marker.contentor.lat : 0,
                      marker.contentor ? marker.contentor.lng : 0
                    )
                  ">
                  <l-icon
                    :icon-size="dynamicSize"
                    :icon-anchor="dynamicAnchor"
                    :icon-url="
                      API_URL+'/img/' +
                      getColorNivelSensor(marker.contentor.nivel) +
                      '.png'
                    "/>
                  <l-popup>
                    <div>
                      Contentor: {{ marker.contentor.numero_contentor }}
                      <br />
                      <v-chip
                        :color="getColorNivelSensorTabela(marker.contentor.nivel)"

                        small
                        >{{ marker.contentor.nivel }}%</v-chip
                      >
                      <p>
                        Rua: {{ marker.contentor.rua }}
                        <br />
                        Freguesia: {{ getDesignacaoFreguesia(freguesias, marker.contentor.localidade) }}
                        <br />
                      </p>
                    </div>
                  </l-popup>
                </l-marker>
              </template>
              <l-marker
                :lat-lng="getlatLng(41.38150949313275, -8.380767029624579)">
                <l-icon
                  :icon-size="dynamicSize"
                  :icon-anchor="dynamicAnchor"
                  :icon-url="API_URL+'/img/resinorte.png'"/>
                <l-popup>
                  <div>Resinorte</div>
                </l-popup>
              </l-marker>

              <l-geo-json
                v-if="geojson"
                :geojson="geojson"
                :options-style="myStyle">
              </l-geo-json>
              <l-tile-layer :url="url" :attribution="attribution" />
            </l-map>
          </v-card>
        </v-col>
      </v-row>
      <div class="text-center">
        <v-dialog v-model="dialog" style="z-index: 9999" width="500">
          <v-card :disabled = "loaders.savingRota">
            <v-card-title class="headline grey lighten-2" primary-title>
              Rota dos Contentores
            </v-card-title>
            <v-card-text>
              <v-form ref="formRotas" lazy-validation>
                <v-text-field
                  v-model="selecionado.nome"
                  :counter="40"
                  label="Nome referência"
                  :rules="[rules.required]">
                </v-text-field>
                <v-select
                  v-model="selecionado.tipo_circuito"
                  :items="tipoCircuitos"
                  item-text="circuito"
                  label="Circuitos"
                  return-object
                  :rules="[rules.required]">
                </v-select>
                <v-select
                  v-model="selecionado.diaDaSemana"
                  :items="semana"
                  item-value="id"
                  item-text="dia"
                  label="Dia da Semana"
                  :rules="[rules.required]">
                </v-select>
                <v-select
                  v-model="selecionado.tipo"
                  :items="tipo"
                  item-text="tipo"
                  label="Tipo"
                  return-object
                  :rules="[rules.required]">
                </v-select>
                <draggable :list="selecionado.Contentors">
                  <template>
                    <v-list-item
                      v-for="item in selecionado.Contentors"
                      :key="item.contentor.id"
                      :set="fregDesignacao=getDesignacaoFreguesia(freguesias, item.contentor.localidade)">
                      <template>
                        <v-list-item-content v-if="!item.endereco">
                          <v-list-item-subtitle class="text--primary">
                            {{ item.contentor.numero_contentor }} -
                            {{
                              item.contentor.rua +
                              " - " +
                              fregDesignacao
                            }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{item.contentor.referencia}}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-content v-else>
                          <v-list-item-subtitle class="text--primary">
                            {{ item.endereco }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-text-field
                          v-if="openOBS == item.contentor.id"
                          v-model="item.obs"
                          :counter="40"
                          label="Observação">
                        </v-text-field>
                        <v-list-item-action
                          @click="
                            openOBS =
                              openOBS == item.contentor.id
                                ? 0
                                : item.contentor.id
                          ">
                          <v-icon color="blue">
                            {{
                              item.obs == null || item.obs.length == 0
                                ? "mdi-information-outline"
                                : "mdi-information"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-action @click="showDialogDelete=true; dialogDeleteObj = item">
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-list-item-action>
                      </template>
                      <!--<v-divider></v-divider>-->
                    </v-list-item>
                  </template>
                </draggable>
                <v-autocomplete
                  v-model="contentor"
                  :items="contentors"
                  item-text="autocomplete"
                  label="Contentores"
                  class="mr-4"
                  return-object
                  :rules="[rules.required]">
                </v-autocomplete>

                <v-btn
                  :disabled="contentor && contentor.numero_contentor == null"
                  color="success"
                  class="mr-4"
                  @click="addContentor()">
                  Adicionar
                </v-btn>

                <v-btn
                  color="success"
                  class="mr-4"
                  @click="validateForm"
                  :loading = "loaders.savingRota"
                  :disabled = "loaders.savingRota">
                  Salvar
                </v-btn>

                <v-btn color="error" class="mr-4" @click="dialog = false">
                  Cancelar
                </v-btn>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </v-dialog>
      </div>
      <dialog-confirmation
        :showDialog = "showDialogDelete"
        :question = "'Deseja remover o contentor?'"
        @hideDialog = "showDialogDelete=false"
        @responseDialog = "handleReponseDelete">
      </dialog-confirmation>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import mixin from '../plugins/mixin'
import { icon, latLng } from "leaflet";
import draggable from "vuedraggable";
import DialogConfirmation from '../components/DialogConfirmation.vue';
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LGeoJson,
  LIcon,
} from "vue2-leaflet";

export default {
  name: "InsCirc",
  mixins: [mixin],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LGeoJson,
    LIcon,
    draggable,
    DialogConfirmation,
  },
  data: () => ({
    API_URL: process.env.VUE_APP_API_URL,
    tipoCircuitos: [],
    openOBS: 0,
    semana: [
      { id: 0, dia: "Domingo" },
      { id: 1, dia: "Segunda-Feira" },
      { id: 2, dia: "Terça-Feira" },
      { id: 3, dia: "Quarta-Feira" },
      { id: 4, dia: "Quinta-Feira" },
      { id: 5, dia: "Sexta-Feira" },
      { id: 6, dia: "Sábado" },
    ],
    contentors: [],
    contentor: {},
    endereco: "",
    dialog: false,
    myStyle: {
      weight: 1,
      color: "#7366aa",
      fillColor: "#92abd2",
    },
    rules: {
      required: (value) => (value!=null && value !=undefined) || "Preenchimento obrigatório",
    },
    hstCircuitosHeader: [
      { text: "Nome", value: "nome", align: 'center' },
      { text: "Tipo", value: "tipo", align: 'center' },
      { text: "Circuito", value: "tipo_circuito", align: 'center' },
      { text: "Nº Contentores", value: "Contentors", align: 'center' },
      { text: "Visualizar", value: "id", sortable: true, align: 'center' },
      { text: "editar", value: "editar", sortable: true, align: 'center' },
    ],
    circuitos: [],
    freguesias: [],
    search: "",
    tipo: [],
    selecionado: { tipo: 2 },
    markers: [
      {
        id: "m1",
        position: { lat: 47.41322, lng: -1.2 },
        tooltip: "tooltip for marker1",
        icon: icon.glyph({
          prefix: "mdi",
          glyph: "trash-can-outline",
        }),
      },
    ],
    caminhoes: [],
    zoom: 11,
    center: latLng(41.460349, -8.3771063, 12),
    url:
      "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png",
    attribution: "",
    withPopup: latLng(47.41322, -1.219482),
    withTooltip: latLng(47.41422, -1.250482),
    currentZoom: 11.5,
    currentCenter: latLng(47.41322, -1.219482),
    showParagraph: false,
    mapOptions: {
      zoomSnap: 0.5,
    },
    showMap: true,
    geojson: null,
    iconSize: 45,
    showDialogDelete: false,
    dialogDeleteObj: null,
    loaders: {
      rotas: false,
      savingRota: false,
    },

  }),
  methods: {
    handleReponseDelete: function(response) {
      if(response) {
        this.selecionado.Contentors = this.selecionado.Contentors.filter(
          (item) => item !== this.dialogDeleteObj
        )
      };
      this.dialogDeleteObj = null;
    },
    addContentor() {
      if (!this.selecionado.Contentors) this.selecionado.Contentors = [];
      this.selecionado.Contentors.push({ contentor: this.contentor });

      this.contentor = {};
    },
    addEndereco() {
      if (!this.selecionado.Contentors) this.selecionado.Contentors = [];
      this.selecionado.Contentors.push({
        endereco: this.endereco,
        contentor: {},
      });

      this.endereco = "";
    },
    validateForm() {
      if (this.$refs.formRotas.validate()) {
        this.saveRota();
      }
    },
    saveRota() {
      this.loaders.savingRota = true;
      if (this.selecionado.id)
        this.$http
          .put("rotas/" + this.selecionado.id, this.selecionado, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then(() => {
            this.$refs.formRotas.reset();
            this.getRotas();
            this.dialog = false;
            this.loaders.savingRota = false;
          })
          .catch((error) => {
              console.log(error);
              this.loaders.savingRota = false;
            });
      else {
        this.selecionado.dataCriacao = new Date();
        this.$http
          .post("rotas/", this.selecionado, {
            headers: {
              Authorization: `Bearer ${this.getObjeto("token")}`,
            },
          })
          .then(() => {
            this.$refs.formRotas.reset();
            this.getRotas();
            this.dialog = false;
            this.loaders.savingRota = false;
          })
          .catch((error) => {
              console.log(error);
              this.loaders.savingRota = false;
          });
      }
      this.selecionado = {};
      this.contentor = {};
      this.dialog = false;
    },
    getColorNivelSensorTabela(nivel) {
      if (nivel > 74) return "#f24747";
      else if (nivel > 49) return "#fa8748";
      else if (nivel > 24)return "#ccc800";
      else return "#3a983e";
    },
    getColorNivelSensor(nivel) {
      if (nivel > 75) return "red";
      else if (nivel > 55) return "orange";
      else if (nivel) return "green";
      else return "white";
    },
    editarRota(item) {
      this.selecionadoLimpar();
      this.selecionado = item;
      this.dialog = true;
    },
    selecionadoLimpar() {
      this.selecionado = { tipo: 3 };
    },
    imagemFoto(imagem) {
      return this.API_URL+`${imagem}`;
    },
    getlatLng: function (lat, lng) {
      return { lat, lng };
    },
    updateZoom: function (e) {
      this.zoom = e;
    },
    centerZoom(position) {
      this.zoom = 17;
      this.zoomUpdate = 17;
      this.center = latLng(0, 0);
      setTimeout(() => {
        this.center = position;
      }, 500);
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    pesquisarCircuito(item) {
      this.selecionado = item;
    },
    getListas() {
      this.$http
        .get("tipo-circuitos", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((tipo) => {
          this.tipoCircuitos = tipo.data;
        });

      this.$http
        .get("tipoRotas", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((tipoRotas) => {
          this.tipo = tipoRotas.data;
        });
      this.$http
        .get("contentors?_limit=50000&status=true", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((contentors) => {
          this.contentors = contentors.data;
          this.contentors.forEach((item) => {
            console.log(item);
            item.autocomplete =
              item.numero_contentor +
              " - " +
              item.rua +
              " - " +
              (item.referencia ? item.referencia + " - " : "") +
              item.localidade.designacao;
          });
        });
      this.getRotas();
    },
    getRotas() {
      this.loaders.rotas = true;
      this.circuitos = [];
      this.$http
        .get("rotas/count", {
          headers: {
            Authorization: `Bearer ${this.getObjeto("token")}`,
          },
        })
        .then((qntRotas) => {
          for (
            let index = 0;
            index < Math.round(qntRotas.data / 10) + 1;
            index++
          ) {
            this.$http
            //todo
              .get("rotas?_limit=10&_sort=nome%3AASC&_start=" + index * 10, {
                headers: {
                  Authorization: `Bearer ${this.getObjeto("token")}`,
                },
              })
              .then((responseRotas) => {
                responseRotas.data.forEach((circuito) => {
                  this.circuitos.push(circuito);
                });
                this.loaders.rotas = false;
              });
          }
        })
      .catch((error) => {
      console.log(error);
      this.loaders.rotas = false;
      });
    },
    getObjeto(nome) {
      return localStorage.getItem(nome)
        ? JSON.parse(localStorage.getItem(nome))
        : null;
    },
    setObjeto(nome, objeto) {
      return localStorage.setItem(nome, JSON.stringify(objeto));
    },
    convertData(data) {
      return new Date(data).toLocaleString();
    },
  },
  computed: {
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  mounted() {
  },
  created() {
    this.getListas();
    this.getFreguesias().then((resp) => {
      this.freguesias = resp.data.data.freguesias
    });
    this.$http
      .get(this.API_URL+"/guimaraes.geojson")
      .then((response) => {
        this.geojson = response.data;
      });
  },
};
</script>
